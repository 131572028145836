/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var FEATURES = {

    //Mmenu function
    mobileMenu: function(yourMenu, yourButton) {
      //Init Mmenu
      $(yourMenu).mmenu({
        // options
        'offCanvas': {
            position:     'right',
            zposition:    'front',
            pageSelector: '#page-wrapper',
            offCanvas: false
         },
        'extensions': [
            'shadow-page',
         ],
         'searchfield':{
          add: true,
          search: false
        }
      });
      //Seach Function
      $("#nav-mobile .mm-search input").keyup(function(e){
          if(e.keyCode === 13){
              var newSearch = location.protocol + '//' + location.hostname + '/?s=' + $(this).val();
              console.log(newSearch);
              document.location.assign(newSearch);
          }
      });

      //Set var for API calls
      var $icon = $(yourButton);
      var API = $(yourMenu).data('mmenu');

      //On click open
      $icon.on( 'click', function() {
        API.open();
      });

      //Add remove opened class for button animations
      API.bind( 'open:finish', function() {
         setTimeout(function() {
            $icon.addClass( 'is-active' );
         }, 50);
      });
      API.bind( 'close:finish', function() {
         setTimeout(function() {
            $icon.removeClass( 'is-active' );
         }, 50);
      });
    },

    //Add class to menu item even when it's and archive
    currentMenuItem: function(){
      var current   = window.location.href;
      var link      = '.menu-item>a[href="' + current +'"]';
      $(link).parent().addClass('current-menu-item');
    },

    //Youtube API settings
    youTubeMute: function(){
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      var player;
      function onPlayerReady() {
        player.mute();
      } 
      window.onYouTubeIframeAPIReady = function() {
        player = new YT.Player('fitvid0', {
          events: {
            'onReady': onPlayerReady,
          }
        });
      };
    },

    //Footer Sign Up
    footerEmail: function(){
      $('.email-ref').click(function(){
        event.preventDefault();
        var emailHolder = $(this).siblings().children('input');
        var userEmail   = emailHolder.val();
        var urlParam    = '/take-action/email-signup/?email=' + userEmail + '#topform';
        window.location = urlParam;
      });
      $('.email-var').keydown(function(event){
        if(event.keyCode === 13) {
          event.preventDefault();
          $(this).parent().siblings('.email-ref').click();
          return false;
        }
      });
    },
    addEmail: function(){
      //Function to parse out url string parameters. Pass in the key as an argument to get the value
      function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1));
        var sURLVariables = sPageURL.split('&');
        var sParameterName;
        var i;
        for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : sParameterName[1];
          }
        }
      }
      var userEmail = getUrlParameter('email');
      if (userEmail !== '' || userEmail !== null){
        $('#recieved-email').val(userEmail);
      }
    },

    //Slick slider for HP projects
    featuredSlider: function(){
      $('.project-slider-wrapper').slick({
        // autoplay: true,
        autoplaySpeed: 6000,
        speed: 1000,
        arrows: false,
      });
      $('.project-slider-wrapper').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var nextSlideTab = 'a[data-slide="' + nextSlide + '"]';
        var currentSlideTab = 'a[data-slide="' + currentSlide + '"]';
        $(currentSlideTab).parent().removeClass('current');
        $(nextSlideTab).parent().addClass('current');
      });
      $('a[data-slide]').click(function(e) {
        e.preventDefault();
        $('a[data-slide]').parent().removeClass('current');
        $(this).parent().addClass('current');
        var slideno = $(this).data('slide');
        $('.project-slider-wrapper').slick('slickGoTo', slideno);
      });  
    },
    //Slick slider for Timeline
    timelinedSlider: function(){
      $('.timeline-slider').slick({
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        prevArrow: '.left-slide',
        nextArrow: '.right-slide',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      }); 
    },
    //Posts Filter

    filterPosts: function(){
      //Function to parse out url string parameters. Pass in the key as an argument to get the value
      function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1));
        var sURLVariables = sPageURL.split('&');
        var sParameterName;
        var i;
        for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
              return sParameterName[1] === undefined ? true : sParameterName[1];
          }
        }
      }
      //AJAX Call
      var ajax_url = post_filter.ajax_url;
      function getPostsCat(){
        var post_type = $('#category-post-content').data('post');
        var category  = getUrlParameter('category');
        var order     = getUrlParameter('order');
        var pageNum   = getUrlParameter('pageNum');
        var offset    = getUrlParameter('offset');
        console.log(pageNum);
        if(typeof pageNum === 'undefined'){
          pageNum = 1;
        }

        $.ajax({
          type: 'GET',
          url: ajax_url,
          data: {
            action: 'post_filter', 
            post_type: post_type,
            category: category,
            orderby: order,
            page_number: pageNum,
            offset: offset,
          },
          beforeSend: function (){
            //FadeOut
            var children = $('#category-post-content').children();
            children.fadeOut('slow');
          },
          success: function(data){
            //Fade in new content
            $('#category-post-content').fadeIn('slow');
            $('#category-post-content').html(data);
          },
          error: function(){
            console.log('no posts found');
          }
        }); 
      }

      //Functiont to update URL parameters and call the AJAX function
      function updateUrl(params){
        str = jQuery.param(params);
        history.pushState(null, null, '?' + str);
        getPostsCat();
      }

      //Show all posts on page load
      $( document ).ready(function() {
        getPostsCat();
      });

      //Make item from dropdown active
      $('.dropdown-item').click(function(){
        var dataId       = '#' + $(this).data('parent'); //id of the dropdown select box so we know where to updated the selected text
        var parentId     = '#' + $(this).parent().attr('id'); //Get the id of the parent so we can specifically target all dropdown items in this field w/o interfering with others that might be on the page
        var term         = $(this).data('term');
        var term_name    = $(this).text();
        //Remove selected class from all items in this particular dropdown
        $(parentId + '>.dropdown-item').removeClass('selected');
        $(this).addClass('selected');
        $(dataId).empty();
        $(dataId).text(term_name);
        $(dataId).dropdown("toggle");
        return false;
      });

      //Click function to filter posts
      $('#filter_search').click( function(){
        var params   = {
          category: $('#catOptions > .selected').data('term'),
          order: $('#orderOptions > .selected').data('term'),
          pageNum: 1,
          offset: 0,
        };
        updateUrl(params);
      });

      //Reset filters
      $('#filter_reset').click( function(){
        var catDefault    = $('.cat_dropdown').data('default');
        var orderDefault  = $('.order_dropdown').data('default');
        $('.cat_dropdown').empty().text(catDefault);
        $('.order_dropdown').empty().text(orderDefault);
        $('.dropdown-item').removeClass('selected');
        var params   = {
          category: '',
          order: '',
          pageNum: 1,
          offset: 0,
        };
        updateUrl(params);
      });

      //Pagination functions
      //Get page href and call AJAX fucntion
      $('#category-post-content').click(function(event){
        var target = $(event.target);
        var params = {
          category: getUrlParameter('category'),
          order:    getUrlParameter('order'),
        };

        if(target.hasClass('page-numbers')){
          event.preventDefault();
          params.pageNum = target.data('page');
          params.offset  = (target.data('page') * 12) - 12;
          updateUrl(params);
        }
        else if(target.hasClass('previous')){
          var prevPage   = parseInt(getUrlParameter('pageNum')) - 1;
          params.pageNum = prevPage;
          params.offset  = (prevPage * 12) - 12;
          event.preventDefault();
          updateUrl(params);
        }
        else if(target.hasClass('next')){
          var nextPage = parseInt(getUrlParameter('pageNum'))+ 1;
          if(isNaN(nextPage)){
            nextPage = 2;
          }
          params.pageNum = nextPage;
          params.offset  = (nextPage * 12) - 12;
          event.preventDefault();
          updateUrl(params);
        }
      });
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        FEATURES.mobileMenu('#nav-mobile', '#open-nav');
        FEATURES.youTubeMute();
        FEATURES.currentMenuItem();
        FEATURES.footerEmail();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        FEATURES.featuredSlider();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
        FEATURES.timelinedSlider();
      }
    },
    'page_template_template_blog': {
      init: function() {
        FEATURES.filterPosts();
      }
    },
    'email_signup': {
      init: function() {
        // JavaScript to be fired on the email signup page
        FEATURES.addEmail();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
